// windowSizeComposition.js

import { ref, onMounted, onBeforeUnmount } from 'vue';

export function detectScreenSize() {
  const isMobile = ref();
  const route = useRoute();
  function removeFirstMatch(inputString, substringToRemove) {
    const index = inputString.indexOf(substringToRemove);
  
    if (index === -1) {
      // The substring was not found, return the original string
      return inputString;
    }
  
    // Use slice to remove the first matched substring
    const updatedString = inputString.slice(0, index) + inputString.slice(index + substringToRemove.length);
  
    return updatedString;
  }
  
  function isMobileFunction(event) {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    /* calculate isMobile by window width.   */
    if ((!event || !isMobile.value) && width < 1024) {
      isMobile.value = true;
    } 
    if ((!event || isMobile.value) && width >=1024) {
      isMobile.value = false;
    }
    
  }
  watch(isMobile,()=>{
    if (isMobile.value) {
      navigateTo("/mobile");
    } else {
      navigateTo(removeFirstMatch(route.fullPath, '/mobile'));
    }
  })
  onMounted(() => {
    isMobileFunction();
    window.addEventListener('resize', isMobileFunction);
  });
  
  onBeforeUnmount(() => {
    window.removeEventListener('resize', isMobileFunction);
  });
}
